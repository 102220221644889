.partnership-link {
    color: #F7CF77;
    text-decoration: underline;
    font-weight: 600;
    transition: all 0.2s ease;
  }
  
  .partnership-link:hover {
    color: #e9b958;
    text-decoration: underline;
  }