/* Import Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Base Styles */
.App {
  text-align: center;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, sans-serif;
  color: #1a1a2e;
  line-height: 1.6;
}

/* Typography */
h2 {
  color: #0f4c75;
  margin-top: 3.5rem;
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: -0.5px;
}

p {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 1.5rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Layout */
.main-section {
  padding: 4rem 1.5rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Header */
.App-header {
  background: linear-gradient(135deg, #0f4c75 0%, #1b262c 100%);
  background-image: url('./assets/header-pattern.svg'), linear-gradient(135deg, #0f4c75 0%, #1b262c 100%);
  background-size: cover;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 3rem 1rem;
  position: relative;
  overflow: hidden;
}

.App-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(to top left, #fff 0%, transparent 50%);
}

.App-header h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  letter-spacing: -1px;
  background: linear-gradient(90deg, #fff, #bbe1fa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tagline {
  font-size: 1.6rem;
  font-weight: 300;
  max-width: 600px;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: linear-gradient(90deg, #ffffff, #e1f5fe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.5px;
}

.logo {
  max-width: 90%;
  width: 820px;
  height: auto;
  margin-bottom: 1.5rem;
  transition: width 0.3s ease;
}

/* Feature Headings and Text */
.feature-heading {
  color: #0f4c75;
  font-size: 2.8rem;
  font-weight: 700;
  margin-top: 4rem;
  margin-bottom: 1.8rem;
  letter-spacing: -0.5px;
  position: relative;
  display: inline-block;
}

.feature-heading::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 80px;
  height: 4px;
  background: linear-gradient(90deg, #0f4c75, #3282b8);
  border-radius: 2px;
}

.feature-text {
  font-size: 1.15rem;
  line-height: 1.8;
  color: #333;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

/* Hero Section */
.hero-section {
  background: linear-gradient(135deg, rgba(15, 76, 117, 0.03) 0%, rgba(50, 130, 184, 0.07) 100%);
  border-radius: 20px;
  padding: 3rem 2rem;
  margin: 2rem 0 4rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background: linear-gradient(135deg, transparent 0%, rgba(50, 130, 184, 0.1) 100%);
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.hero-content {
  position: relative;
  z-index: 1;
}

.feature-highlights {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2.5rem;
  justify-content: center;
}

.highlight-item {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.8rem 1.5rem;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.highlight-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
}

.highlight-icon {
  font-size: 1.5rem;
}

.highlight-text {
  font-weight: 600;
  color: #0f4c75;
}

/* Tech Cards */
.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.tech-card {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.6s ease-out forwards;
}

.tech-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
  border-color: rgba(15, 76, 117, 0.2);
}

.tech-header {
  background: linear-gradient(135deg, rgba(15, 76, 117, 0.03) 0%, rgba(50, 130, 184, 0.1) 100%);
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.tech-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.tech-card h3 {
  margin: 0;
  color: #0f4c75;
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
}

.tech-card h3::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #0f4c75, #3282b8);
  border-radius: 3px;
}

.tech-card p {
  padding: 1.5rem 2rem;
  margin: 0;
  font-size: 1rem;
  text-align: left;
  color: #555;
  margin-top: 1.5rem;
  flex-grow: 1;
}

/* Animation delays for tech cards */
.tech-card:nth-child(2) {
  animation-delay: 0.2s;
}

.tech-card:nth-child(3) {
  animation-delay: 0.4s;
}

.tech-card:nth-child(4) {
  animation-delay: 0.6s;
}

/* Scalable Solutions Section */
.solutions-card {
  margin: 3rem 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
}

.solutions-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  align-items: center;
  gap: 3rem;
}

.solutions-visual {
  flex: 1;
  min-width: 280px;
  background: linear-gradient(135deg, rgba(15, 76, 117, 0.03) 0%, rgba(50, 130, 184, 0.1) 100%);
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.solutions-content {
  flex: 2;
  min-width: 300px;
  padding: 2rem;
  text-align: left;
}

.solutions-content h3 {
  color: #0f4c75;
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.solutions-content p {
  text-align: left;
  margin-left: 0;
  max-width: none;
}

/* Scaling Graphic */
.scaling-graphic {
  position: relative;
  width: 240px;
  height: 180px;
  display: flex;
  align-items: flex-end;
}

.scaling-item {
  background: linear-gradient(135deg, #0f4c75, #3282b8);
  border-radius: 6px;
  margin: 0 8px;
  transition: all 0.5s ease;
  box-shadow: 0 5px 15px rgba(15, 76, 117, 0.15);
}

.scaling-item.small {
  width: 50px;
  height: 70px;
}

.scaling-item.medium {
  width: 65px;
  height: 110px;
}

.scaling-item.large {
  width: 80px;
  height: 150px;
}

.scaling-arrow {
  position: absolute;
  top: 30px;
  left: 15%;
  width: 70%;
  height: 16px;
  background: linear-gradient(90deg, transparent, #3282b8);
  clip-path: polygon(0 40%, 90% 40%, 90% 0, 100% 50%, 90% 100%, 90% 60%, 0 60%);
}

/* Solution Benefits */
.solution-benefits {
  list-style-type: none;
  padding-left: 0;
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.solution-benefits li {
  position: relative;
  padding-left: 1.8rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 1rem;
}

.solution-benefits li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #3282b8;
  font-weight: bold;
}

/* Testimonial Section */
.testimonial-section {
  margin: 4rem 0;
}

.testimonial-card {
  display: flex;
  background: linear-gradient(135deg, rgba(15, 76, 117, 0.05) 0%, rgba(50, 130, 184, 0.1) 100%);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(15, 76, 117, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(15, 76, 117, 0.12);
}

.testimonial-content {
  padding: 2.5rem;
  position: relative;
  flex: 3;
}

.testimonial-image {
  flex: 1;
  min-width: 200px;
  background-color: #203063;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
}

.testimonial-image img {
  max-width: 100%;
  max-height: 180px;
  object-fit: contain;
}

.quote-mark {
  font-size: 6rem;
  font-family: Georgia, serif;
  color: rgba(15, 76, 117, 0.15);
  position: absolute;
  top: -0.5rem;
  left: 1.5rem;
  line-height: 1;
}

.testimonial-text {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #444;
  margin-bottom: 2rem;
  font-style: italic;
  position: relative;
  z-index: 1;
}

/* Client Info Styles */
.client-info {
  border-top: 1px solid rgba(15, 76, 117, 0.1);
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.client-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  width: 100%;
}

.client-photo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #203063;
  margin-right: 1.5rem;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
}

.client-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.client-info h4 {
  margin: 0 0 0.3rem 0;
  color: #0f4c75;
  font-size: 1.2rem;
  text-align: left;
}

.client-info p {
  margin: 0 0 0.5rem 0;
  font-size: 0.95rem;
  color: #555;
  font-weight: 500;
  text-align: left;
}

.client-link {
  display: inline-block;
  color: #3282b8;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  position: relative;
  transition: color 0.2s ease;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
}

.client-link:hover {
  color: #0f4c75;
  border-bottom: 1px solid #0f4c75;
}

/* CTA Section */
.cta-section {
  margin: 5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.cta-button {
  padding: 1rem 2.5rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  margin: 0.5rem;
}

.cta-button.primary {
  background: linear-gradient(135deg, #0f4c75, #3282b8);
  color: white;
  border: none;
  box-shadow: 0 5px 15px rgba(15, 76, 117, 0.3);
}

.cta-button.primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(15, 76, 117, 0.4);
}

.cta-button.secondary {
  background: transparent;
  color: #0f4c75;
  border: 2px solid #0f4c75;
}

.cta-button.secondary:hover {
  background: rgba(15, 76, 117, 0.05);
  transform: translateY(-3px);
}

/* Footer */
.App-footer {
  background-color: #1b262c;
  color: #bbe1fa;
  padding: 3rem 1rem;
  margin-top: 5rem;
}

.App-footer p {
  color: #bbe1fa;
  opacity: 0.8;
  font-size: 1rem;
  max-width: none;
  margin-bottom: 0.5rem;
}

/* Animation Keyframes */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .App-header h1 {
    font-size: 3rem;
  }
  
  .tagline {
    font-size: 1.3rem;
  }
  
  h2, .feature-heading {
    font-size: 2.2rem;
  }
  
  .tech-grid {
    grid-template-columns: 1fr;
  }
  
  .cta-button {
    width: 80%;
  }
  
  .testimonial-card {
    flex-direction: column;
  }
  
  .testimonial-image {
    order: -1;
    min-height: 150px;
    padding: 1rem;
  }
  
  .testimonial-content {
    padding: 2rem 1.5rem;
  }
  
  .quote-mark {
    font-size: 4rem;
    top: -0.2rem;
    left: 1rem;
  }
  
  .solutions-container {
    flex-direction: column;
  }
  
  .solutions-visual {
    order: -1;
    min-height: 200px;
  }
  
  .solution-benefits {
    grid-template-columns: 1fr;
  }
  
  .feature-highlights {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .highlight-item {
    width: 80%;
  }
}

@media (max-width: 576px) {
  .client-profile {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .client-photo {
    margin-bottom: 1rem;
    margin-right: 0;
  }
  
  .client-details {
    align-items: center;
  }
  
  .client-info h4,
  .client-info p {
    text-align: center;
  }
}


/* Add to your App.css file */

/* Navbar Styles */
.navbar {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.08);
  position: sticky;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.nav-logo {
  height: 40px;
  width: auto;
}

.nav-menu {
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-left: 2rem;
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  transition: color 0.3s ease;
  padding: 0.5rem 0;
}

.nav-link:hover {
  color: #0f4c75;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #0f4c75, #3282b8);
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
}

.contact-link {
  background-color: #0f4c75;
  color: white;
  padding: 0.5rem 1.2rem;
  border-radius: 50px;
  transition: all 0.3s ease;
}

.contact-link:hover {
  color: white;
  background-color: #0c3c5d;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(15, 76, 117, 0.2);
}

.contact-link::after {
  display: none;
}

.mobile-menu-toggle {
  display: none;
  cursor: pointer;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
}

.hamburger span {
  width: 100%;
  height: 3px;
  background-color: #0f4c75;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.hamburger.open span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Portfolio/Work Section */
.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.portfolio-item {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  height: 250px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.portfolio-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.portfolio-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.5rem;
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.portfolio-item:hover .portfolio-overlay {
  opacity: 1;
}

.portfolio-overlay h3 {
  margin: 0 0 0.5rem;
  font-size: 1.5rem;
}

.portfolio-overlay p {
  margin: 0 0 1rem;
  font-size: 0.9rem;
  opacity: 0.9;
}

.portfolio-link {
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 50px;
  text-decoration: none;
  display: inline-block;
  font-size: 0.9rem;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
}

.portfolio-link:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.portfolio-placeholder {
  background: linear-gradient(135deg, #f5f7fa 0%, #e4eaef 100%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.5rem;
  color: #0f4c75;
}

.portfolio-placeholder h3 {
  margin: 0 0 0.5rem;
  font-size: 1.5rem;
}

.portfolio-placeholder p {
  margin: 0;
  opacity: 0.7;
  font-size: 0.9rem;
  max-width: 250px;
}

/* Contact Section */
.contact-section {
  margin: 5rem 0;
}

.contact-container {
  display: flex;
  gap: 3rem;
  margin: 3rem 0;
}

.contact-info {
  flex: 1;
  text-align: left;
}

.contact-info h3 {
  font-size: 1.8rem;
  color: #0f4c75;
  margin-bottom: 1rem;
}

.contact-info p {
  text-align: left;
  margin-left: 0;
}

.contact-methods {
  margin-top: 2rem;
}

.contact-method {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.contact-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, rgba(15, 76, 117, 0.1) 0%, rgba(50, 130, 184, 0.2) 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-text h4 {
  margin: 0 0 0.3rem 0;
  color: #0f4c75;
  font-size: 1.1rem;
}

.contact-text a {
  color: #444;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-text a:hover {
  color: #0f4c75;
}

.contact-form {
  flex: 1;
  background: linear-gradient(135deg, rgba(15, 76, 117, 0.03) 0%, rgba(50, 130, 184, 0.1) 100%);
  padding: 2rem;
  border-radius: 16px;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #0f4c75;
  text-align: left;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.8rem 1rem;
  border: 1px solid rgba(15, 76, 117, 0.2);
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: #444;
  transition: all 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #0f4c75;
  box-shadow: 0 0 0 3px rgba(15, 76, 117, 0.1);
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

/* Footer */
.App-footer {
  background-color: #1b262c;
  color: #bbe1fa;
  padding: 4rem 2rem 2rem;
  margin-top: 5rem;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-brand {
  flex: 2;
  margin-right: 2rem;
}

.footer-logo {
  height: 50px;
  margin-bottom: 1rem;
}

.footer-brand p {
  color: #bbe1fa;
  opacity: 0.8;
  margin-left: 0;
  text-align: left;
}

.footer-links {
  flex: 3;
  display: flex;
  justify-content: space-around;
}

.footer-column {
  flex: 1;
  margin: 0 1rem;
}

.footer-column h4 {
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column li {
  margin-bottom: 0.8rem;
}

.footer-column a {
  color: #bbe1fa;
  text-decoration: none;
  opacity: 0.8;
  transition: all 0.3s ease;
  font-size: 0.95rem;
}

.footer-column a:hover {
  opacity: 1;
  color: #fff;
  padding-left: 5px;
}

.copyright {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 1.5rem;
  text-align: center;
}

.copyright p {
  margin: 0;
  color: #bbe1fa;
  opacity: 0.6;
  font-size: 0.9rem;
}

/* Media Queries for Responsive Design */
@media (max-width: 992px) {
  .contact-container {
    flex-direction: column;
  }
  
  .contact-info, 
  .contact-form {
    width: 100%;
  }
  
  .footer-content {
    flex-direction: column;
  }
  
  .footer-brand {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .footer-brand p {
    text-align: center;
  }
  
  .footer-links {
    flex-direction: column;
  }
  
  .footer-column {
    margin-bottom: 2rem;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .navbar-container {
    padding: 1rem;
  }
  
  .mobile-menu-toggle {
    display: block;
  }
  
  .nav-menu {
    position: fixed;
    top: 60px;
    left: -100%;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: white;
    padding: 2rem 0;
    transition: all 0.3s ease;
    z-index: 999;
  }
  
  .nav-menu.active {
    left: 0;
  }
  
  .nav-item {
    margin: 1.5rem 0;
  }
  
  .nav-link {
    font-size: 1.2rem;
  }
  
  .contact-link {
    padding: 0.7rem 2rem;
    margin-top: 1rem;
  }
}

/* Add to your App.css file - Contact form responsive fixes */

/* Contact Section - Base Styles */
.contact-section {
  margin: 5rem 0;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin: 3rem 0;
  width: 100%;
  max-width: 100%;
}

.contact-info {
  flex: 1;
  min-width: 280px;
  text-align: left;
}

.contact-form {
  flex: 1;
  min-width: 280px;
  background: linear-gradient(135deg, rgba(15, 76, 117, 0.03) 0%, rgba(50, 130, 184, 0.1) 100%);
  padding: 2rem;
  border-radius: 16px;
  max-width: 100%;
  box-sizing: border-box;
}

.form-group {
  margin-bottom: 1.5rem;
  width: 100%;
}

.form-group input,
.form-group textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 0.8rem 1rem;
  border: 1px solid rgba(15, 76, 117, 0.2);
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: #444;
  transition: all 0.3s ease;
  max-width: 100%;
}

/* Media queries specifically for contact form */
@media (max-width: 992px) {
  .contact-container {
    flex-direction: column;
    gap: 2rem;
  }
  
  .contact-info, 
  .contact-form {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .contact-section {
    margin: 3rem 0;
  }
  
  .contact-form {
    padding: 1.5rem;
  }
}

@media (max-width: 576px) {
  .contact-container {
    gap: 1.5rem;
    margin: 2rem 0;
  }
  
  .contact-form {
    padding: 1.25rem;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 0.7rem 0.8rem;
    font-size: 0.95rem;
  }
  
  .contact-method {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .contact-icon {
    margin-bottom: 0.5rem;
  }
}

/* Global container fixes */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  overflow: hidden;
}

/* Global form button fix */
.contact-form .cta-button {
  width: auto;
  max-width: 100%;
}


/* Add these styles at the end of your CSS file */

/* Dark Mode Styles */
.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.05);
}

.dark-mode .theme-toggle {
  background-color: rgba(255, 255, 255, 0.1);
}

.theme-toggle:hover {
  transform: scale(1.1);
}

.mode-icon {
  font-size: 1.4rem;
}

.theme-toggle-item {
  margin-left: 1rem;
  display: flex;
  align-items: center;
}

/* Dark Mode Theme */
body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

/* Navbar */
.dark-mode .navbar {
  background-color: rgba(30, 30, 30, 0.95);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}

.dark-mode .nav-link {
  color: #e0e0e0;
}

.dark-mode .nav-link:hover {
  color: #7cc2ff;
}

.dark-mode .nav-link::after {
  background: linear-gradient(90deg, #3282b8, #7cc2ff);
}

.dark-mode .contact-link {
  background-color: #3282b8;
  color: #e0e0e0;
}

.dark-mode .contact-link:hover {
  background-color: #0f4c75;
}

.dark-mode .hamburger span {
  background-color: #e0e0e0;
}

/* Header */
.dark-mode .App-header {
  background: linear-gradient(135deg, #0c3c5d 0%, #101820 100%);
}

/* Hero Section */
.dark-mode .hero-section {
  background: linear-gradient(135deg, rgba(12, 60, 93, 0.1) 0%, rgba(50, 130, 184, 0.05) 100%);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.dark-mode .highlight-item {
  background-color: #2a2a2a;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

/* Tech Cards */
.dark-mode .tech-card {
  background: #1e1e1e;
  border: 1px solid rgba(50, 130, 184, 0.15);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.dark-mode .tech-header {
  background: linear-gradient(135deg, rgba(12, 60, 93, 0.15) 0%, rgba(50, 130, 184, 0.2) 100%);
}

.dark-mode .tech-card p {
  color: #bbb;
}

.dark-mode .tech-card h3 {
  color: #7cc2ff;
}

/* Feature Heading */
.dark-mode .feature-heading {
  color: #7cc2ff;
}

.dark-mode .feature-text {
  color: #bbb;
}

/* Testimonials */
.dark-mode .testimonial-card {
  background: linear-gradient(135deg, rgba(12, 60, 93, 0.1) 0%, rgba(50, 130, 184, 0.05) 100%);
  border: 1px solid rgba(50, 130, 184, 0.15);
}

.dark-mode .testimonial-text {
  color: #ccc;
}

.dark-mode .testimonial-image {
  background-color: #203063;
}

.dark-mode .client-info h4 {
  color: #7cc2ff;
}

.dark-mode .client-link {
  color: #3282b8;
}

.dark-mode .client-info p {
  color: #bbb;
}

/* Contact Form */
.dark-mode .contact-form {
  background: linear-gradient(135deg, rgba(12, 60, 93, 0.15) 0%, rgba(50, 130, 184, 0.1) 100%);
}

.dark-mode .form-group label {
  color: #7cc2ff;
}

.dark-mode .form-group input,
.dark-mode .form-group textarea {
  background-color: #2a2a2a;
  border: 1px solid rgba(50, 130, 184, 0.3);
  color: #e0e0e0;
}

.dark-mode .form-group input:focus,
.dark-mode .form-group textarea:focus {
  border-color: #3282b8;
  box-shadow: 0 0 0 3px rgba(50, 130, 184, 0.2);
}

.dark-mode .form-group input::placeholder,
.dark-mode .form-group textarea::placeholder {
  color: #888;
}

.dark-mode .contact-icon {
  background: linear-gradient(135deg, rgba(12, 60, 93, 0.2) 0%, rgba(50, 130, 184, 0.3) 100%);
}

.dark-mode .contact-text h4 {
  color: #7cc2ff;
}

.dark-mode .contact-text a {
  color: #bbb;
}

.dark-mode .contact-text a:hover {
  color: #7cc2ff;
}

/* Buttons */
.dark-mode .cta-button.primary {
  background: linear-gradient(135deg, #0c3c5d, #3282b8);
}

.dark-mode .cta-button.secondary {
  color: #3282b8;
  border: 2px solid #3282b8;
}

.dark-mode .cta-button.secondary:hover {
  background: rgba(50, 130, 184, 0.1);
}

/* Footer */
.dark-mode .App-footer {
  background-color: #101820;
}

.dark-mode .footer-column h4 {
  color: #7cc2ff;
}

.dark-mode .footer-column a:hover {
  color: #7cc2ff;
}

/* Scalable Solutions */
.dark-mode .solutions-card {
  background-color: #1e1e1e;
}

.dark-mode .solutions-visual {
  background: linear-gradient(135deg, rgba(12, 60, 93, 0.15) 0%, rgba(50, 130, 184, 0.2) 100%);
}

.dark-mode .solutions-content h3 {
  color: #7cc2ff;
}

.dark-mode .solution-benefits li {
  color: #bbb;
}

.dark-mode .solution-benefits li::before {
  color: #3282b8;
}

/* Mobile Menu in Dark Mode */
@media (max-width: 768px) {
  .dark-mode .nav-menu {
    background-color: #1e1e1e;
  }
}



/* WixMigration.css */

.wix-migration-section {
  margin: 5rem 0;
  width: 100%;
}

.migration-container {

  gap: 3rem;
  margin: 0 0;
}

/* Site Checker Card */
.site-checker-card {
  background: linear-gradient(135deg, rgba(15, 76, 117, 0.03) 0%, rgba(50, 130, 184, 0.07) 100%);
  border-radius: 20px;
  padding: 2.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.site-checker-card h3 {
  color: #0f4c75;
  margin-top: 0;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.site-checker-form {
  margin: 2rem 0;
}

.form-input-group {
  display: flex;
  gap: 1rem;
}

.site-checker-input {
  flex: 1;
  padding: 1rem 1.5rem;
  border-radius: 50px;
  border: 1px solid rgba(15, 76, 117, 0.2);
  font-size: 1.1rem;
  outline: none;
  transition: all 0.3s ease;
}

.site-checker-input:focus {
  border-color: #0f4c75;
  box-shadow: 0 0 0 3px rgba(15, 76, 117, 0.1);
}

.site-checker-button {
  background: linear-gradient(135deg, #0f4c75, #3282b8);
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  border-radius: 50px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.site-checker-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(15, 76, 117, 0.3);
}

.site-checker-button:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.site-checker-error {
  color: #e74c3c;
  margin-top: 1rem;
  font-weight: 500;
}

/* Result Styles */
.site-checker-result {
  display: flex;
  align-items: flex-start;
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-top: 2rem;
  animation: fadeIn 0.5s ease-out forwards;
}

.site-checker-result.is-wix {
  border-left: 4px solid #e74c3c;
  background-color: rgba(231, 76, 60, 0.05);
}

.site-checker-result.not-wix {
  border-left: 4px solid #2ecc71;
  background-color: rgba(46, 204, 113, 0.05);
}

.result-icon {
  font-size: 2rem;
  margin-right: 1.5rem;
  line-height: 1;
}

.result-content h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  color: #333;
}

.result-content p {
  margin: 0;
  font-size: 0.95rem;
  color: #555;
}

/* Benefits Section */
.benefits-section h3 {
  color: #0f4c75;
  margin-top: 0;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
}

.benefit-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.benefit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.benefit-icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.benefit-card h4 {
  color: #0f4c75;
  margin: 0 0 0.8rem 0;
  font-size: 1.2rem;
}

.benefit-card p {
  margin: 0;
  font-size: 0.95rem;
  color: #555;
  line-height: 1.5;
}

/* Migration Process */
.migration-process {
  margin: 4rem 0;
}

.migration-process h3 {
  color: #0f4c75;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.process-steps {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 3rem 0;
}

.process-steps::before {
  content: '';
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, #0f4c75, #3282b8);
  z-index: 0;
}

.process-step {
  position: relative;
  width: 22%;
  text-align: center;
  z-index: 1;
}

.step-number {
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #0f4c75, #3282b8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0 auto 1.5rem;
  box-shadow: 0 5px 15px rgba(15, 76, 117, 0.3);
}

.step-content h4 {
  color: #0f4c75;
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
}

.step-content p {
  margin: 0;
  font-size: 0.9rem;
  color: #555;
}

/* Migration CTA */
.migration-cta {
  background: linear-gradient(135deg, rgba(15, 76, 117, 0.1) 0%, rgba(50, 130, 184, 0.15) 100%);
  padding: 3rem 2rem;
  border-radius: 20px;
  text-align: center;
  margin-top: 4rem;
}

.migration-cta h3 {
  color: #0f4c75;
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

.migration-cta p {
  max-width: 600px;
  margin: 0 auto 2rem;
  font-size: 1.1rem;
}

/* Dark Mode Styles */
body.dark-mode .site-checker-card {
  background: linear-gradient(135deg, rgba(12, 60, 93, 0.1) 0%, rgba(50, 130, 184, 0.05) 100%);
}

body.dark-mode .site-checker-card h3,
body.dark-mode .benefits-section h3,
body.dark-mode .migration-process h3,
body.dark-mode .migration-cta h3 {
  color: #7cc2ff;
}

body.dark-mode .site-checker-input {
  background: #2a2a2a;
  border-color: rgba(50, 130, 184, 0.3);
  color: #e0e0e0;
}

body.dark-mode .site-checker-input::placeholder {
  color: #888;
}

body.dark-mode .site-checker-result {
  background: #1e1e1e;
}

body.dark-mode .site-checker-result.is-wix {
  background-color: rgba(231, 76, 60, 0.1);
}

body.dark-mode .site-checker-result.not-wix {
  background-color: rgba(46, 204, 113, 0.1);
}

body.dark-mode .result-content h4 {
  color: #e0e0e0;
}

body.dark-mode .result-content p {
  color: #bbb;
}

body.dark-mode .benefit-card {
  background: #1e1e1e;
}

body.dark-mode .benefit-card h4 {
  color: #7cc2ff;
}

body.dark-mode .benefit-card p {
  color: #bbb;
}

body.dark-mode .step-content h4 {
  color: #7cc2ff;
}

body.dark-mode .step-content p {
  color: #bbb;
}

body.dark-mode .migration-cta {
  background: linear-gradient(135deg, rgba(12, 60, 93, 0.15) 0%, rgba(50, 130, 184, 0.1) 100%);
}

/* Responsive Styles */
@media (max-width: 992px) {
  .migration-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .benefits-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .process-steps {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
  
  .process-steps::before {
    display: none;
  }
  
  .process-step {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
    gap: 1.5rem;
  }
  
  .step-number {
    margin: 0;
  }
}

@media (max-width: 768px) {
  .form-input-group {
    flex-direction: column;
  }
  
  .site-checker-button {
    width: 100%;
  }
  
  .benefits-grid {
    grid-template-columns: 1fr;
  }
  
  .site-checker-result {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .result-icon {
    margin-right: 0;
    margin-bottom: 1rem;
  }
  
  .migration-cta {
    padding: 2rem 1.5rem;
  }
  
  .migration-cta h3 {
    font-size: 1.8rem;
  }
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Add to your WixMigration.css file or App.css */

.site-checker-error {
  background-color: rgba(231, 76, 60, 0.1);
  border-left: 4px solid #e74c3c;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
}

.error-main {
  color: #c0392b;
  font-weight: 500;
  margin: 0 0 0.5rem 0;
}

.error-details {
  color: #7f8c8d;
  font-size: 0.9rem;
  margin: 0;
  font-family: monospace;
  word-break: break-all;
}

body.dark-mode .site-checker-error {
  background-color: rgba(231, 76, 60, 0.15);
  border-left: 4px solid #e74c3c;
}

body.dark-mode .error-main {
  color: #e74c3c;
}

body.dark-mode .error-details {
  color: #bdc3c7;
}

/* SECTION TO FIX WIDTH STUFF*/
/* Add to your WixMigration.css file or App.css */

/* Improved responsive styles for the migration process */
@media (max-width: 992px) {
  .process-steps {
    flex-direction: column;
    gap: 2.5rem;
  }
  
  .process-steps::before {
    display: none; /* Remove the connecting line */
  }
  
  .process-step {
    width: 100%;
    display: flex;
    align-items: flex-start;
    text-align: left;
    gap: 1.5rem;
  }
  
  .step-number {
    margin: 0;
    min-width: 60px; /* Ensure minimum width */
    height: 60px; /* Maintain circle shape */
    flex-shrink: 0; /* Prevent the circle from shrinking */
  }
  
  .step-content {
    flex: 1;
  }
}

/* Additional mobile adjustments */
@media (max-width: 576px) {
  .process-step {
    gap: 1rem;
  }
  
  .step-number {
    min-width: 50px; /* Still substantial but slightly smaller on very small screens */
    height: 50px;
    font-size: 1.3rem;
  }
  
  .step-content h4 {
    font-size: 1rem;
  }
  
  .step-content p {
    font-size: 0.9rem;
  }
  
  .migration-process h3 {
    font-size: 1.6rem;
  }
}

/* Add to your WixMigration.css or App.css - Improved width handling */
.share-buttons {
  margin-top: 20px;
}

.share-button {
  display: inline-block;
  margin-right: 10px;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

.share-button.whatsapp {
  background-color: #25d366;
}

.share-button.facebook {
  background-color: #3b5998;
}